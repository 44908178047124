










































































import {Vue, Component} from "vue-property-decorator";
import {Delivery} from "@/request/after-sale/Delivery";

@Component({
    name: 'after_sale_delivery'
})
export default class after_sale_delivery extends Vue {
    modal: any = Delivery;
    formData: any = {
        name: '',
        freeConditionStatus: 0, //无条件包邮
        inNum: 1,
        addStepNum: 1,
        inPrice: 1,
        addStepPrice: 1,
        fullNum: 1,
        description: ''
    };
    formRules: any = {
        name: {required: true, trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '运费模版名称',
            key: 'name',
            minWidth: 120
        },
        {
            title: '计算方式',
            key: 'calcType',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.calcType === 0? '按件': p.row.calcType === 1? '按重量': '-')
        },
        {
            title: '包邮条件数量',
            key: 'fullNum',
            minWidth: 120
        },
        {
            title: '描述',
            key: 'description',
            minWidth: 120
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {
    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
        this.formData.inPrice = this.formData.inPrice / 100;
        this.formData.addStepPrice = this.formData.addStepPrice / 100;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        params.inPrice = params.inPrice * 100;
        params.addStepPrice = params.addStepPrice * 100;
        self.$refs['el-table'].doSubmitWithValidate(params).then(() => {

        });
    }
}
